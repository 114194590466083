import React from 'react';
// icon
import { BsArrowUpRight } from 'react-icons/bs';
// motion
import { motion } from 'framer-motion';
// variants
import { fadeIn } from '../variants';

// services data
const services = [
  {
    name: 'DIP & IoC #İsbirligi',
    description:
      'Modüller Arasındaki Bağımlılıkları Yönetmenin Etkili Yolu: DIP ve IoC\'\nin Güçlü İşbirliği',
      buttonName: 'İncele',
    link: 'https://medium.com/@nil1lik/dip-ioc-i%CC%87%C5%9Fbirli%C4%9Fi-3c4549744ec1',
  },
  {
    name: 'MediatoR Pipeline Nedir?',
    description:
      'Yazılım projelerinizde nasıl daha düzenli ve verimli çalışabilirsiniz?',
      buttonName: 'İncele',
    link: 'https://medium.com/@nil1lik/mediator-pipeline-nedir-18e388164c30',
  },
  {
    name: 'Redux Nedir?',
    description:
      'Eğer büyük ve karmaşık projelerde durumu yönetmekte zorlanıyorsanız, Redux tam size göre!',
      buttonName: 'İncele',
    link: 'https://medium.com/@nil1lik/redux-nedir-a1aca658f7ac',
  },
];

const Services = () => {
  return (
    <section className='section' id='services'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row'>
          {/* text & image */}
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 lg:bg-services lg:bg-bottom bg-no-repeat mix-blend-lighten mb-12 lg:mb-0'
          >
            <h2 className='h2 text-accent mb-6'>MAKALELERİM.</h2>
            <h3 className='h3 max-w-[455px] mb-7'>
              Öğrendiklerimi yazıya döktüğüm zaman daha iyi kavradığımı hissediyorum.
            </h3>
            <button onClick={() => window.open("https://medium.com/@nil1lik", "_blank")} className='btn btn-sm'>Medium'u İncele</button>
          </motion.div>
          {/* services */}
          <motion.div
            variants={fadeIn('left', 0.5)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1'
          >
            {/* service list */}
            <div>
              {services.map((service, index) => {
                // destructure service
                const { name, description, buttonName, link } = service;
                return (
                  <div
                    className='border-b border-white/20 h-[146px] mb-[38px] flex'
                    key={index}
                  >
                    <div className='max-w-[476px]'>
                      <h4 className='text-[20px] tracking-wider font-primary font-semibold mb-6'>
                        {name}
                      </h4>
                      <p className='font-secondary leading-tight'>
                        {description}
                      </p>
                    </div>
                    <div className='flex flex-col flex-1 items-end'>
                      <a
                        href={link} 
                        target="_blank"
                        className='btn w-9 h-9 mb-[20px] flex justify-center items-center'
                      >
                        <BsArrowUpRight />
                      </a>
                      <a className='text-gradient text-sm'>
                        {buttonName}
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Services;
