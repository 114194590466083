import React from 'react';
// icon
import { BsArrowUpRight } from 'react-icons/bs';
// motion
import { motion } from 'framer-motion';
// variants
import { fadeIn } from '../variants';

// services data
const services = [
  {
    name: 'Backend',
    description:
      'C#, CQRS, MediatR, Clean Architecture, Layered Architecture, .NET Core, ASP.NET Core, MVC, AutoMapper, AOP,FluentValidation, Middleware',
  },
  {
    name: 'Frontend',
    description:
      'React, TypeScript, Redux, Redux Toolkit,ContextAPI, Axios',
  },
  {
    name: 'Database',
    description:
      'MsSQL, PostgreSQL, Azure, Database Design',
  },
  {
    name: 'Web Teknolojileri',
    description:
      'HTML, CSS, JavaScript',
  },
  {
    name: 'Kişisel Yetkinlikler',
    description:
      'Ekip yönetimi ve çalışması, organize ve planlı çalışma, etkin öğrenme, araştırma ve geliştirme.',
  },
  {
    name: 'Çalışma Alanları',
    description:
      'Github, Draw.io, ExcaliDraw, Notion',
  },
];

const Skills = () => {
  return (
    <section className='section' id='skills'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row mb-20'>
          {/* text & image */}
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 lg:bg-services lg:bg-bottom bg-no-repeat mix-blend-lighten mb-12 lg:mb-0 flex flex-col justify-center'>
            <h2 className='h2 text-accent mb-6'>YETKİNLİKLERİM.</h2>
            <h3 className='h3 max-w-[455px] mb-15'>
              Hakkımda detaylı bilgiye sahip olmak için özgeçmişimi inceleyebilirsiniz.
            </h3>
            <button className='btn btn-sm' style={{ alignSelf: 'start' }}>
            <a
    href='https://drive.google.com/uc?export=download&id=1YiUAUrH-YskQuKI48l3Ia22oTD9qkoC0'
    download="NilBirlikCV.pdf"
    className='text-white'
  >
    Özgeçmişi İndir
  </a>
</button>

          </motion.div>
          {/* services */}
          <motion.div
            variants={fadeIn('left', 0.5)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 relative z-0'
          >
            {/* service list */}
            <div>
              {services.map((service, index) => {
                // destructure service
                const { name, description} = service;
                return (
                  <div
                    className='border-b border-white/15 h-[100px] mb-[18px] flex'
                    key={index}
                  >
                    <div className='max-w-[600px]'>
                    <h4 className='text-[20px] tracking-wider font-semibold mb-1' style={{ fontFamily: 'Ubuntu, sans-serif' }}>
                    {name}
                      </h4>
                      <p className='font-secondary leading-tight'>
                        {description}
                      </p>
                    </div>
                    <div className='flex flex-col flex-0 items-end'>
                    </div>
                  </div>
                );
              })}
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Skills;
