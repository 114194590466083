import React from 'react';
// images
import Image from '../assets/avatar.svg';
// icons
import { FaGithub, FaMedium, FaLinkedin } from 'react-icons/fa';
// type animation
import { TypeAnimation } from 'react-type-animation';
// motion
import { motion } from 'framer-motion';
// variants
import { fadeIn } from '../variants';

const Banner = () => {

  const handleContactClick = () => {
    window.location.href = "mailto:birliknil@gmail.com?subject=Let's%20Work%20Together&body=Hi,%20I%20would%20like%20to%20discuss%20a%20project%20with%20you.";
  };
  
  return (
    <section
      className='min-h-screen flex items-center justify-center'
      id='home'
    >
      <div className='container mx-auto h-full flex items-center justify-center'>
        <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12'>
          {/* text */}
          <div className='flex-1 text-center font-secondary lg:text-left'>
            <motion.h1
              variants={fadeIn('up', 0.3)}
              initial='hidden'
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className='text-[55px] font-bold leading-[0.8] lg:text-[110px] ml-2.5'
            >
              NİL <span>BİRLİK</span>
            </motion.h1>
            <motion.div
              variants={fadeIn('up', 0.4)}
              initial='hidden'
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className='mb-6 text-[36px] lg:text-[47px] font-secondary font-semibold uppercase leading-[1] ml-3.5'
            >
              <span className='text-white mr-4'>Full Stack</span>
              <TypeAnimation
                sequence={[
                  'Developer',
                  5000,
                ]}
                speed={50}
                className='text-accent'
                wrapper='span'
                repeat={Infinity}
              />
            </motion.div>
            <motion.p
              variants={fadeIn('up', 0.5)}
              initial='hidden'
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className='mb-8 max-w-lg mx-auto lg:mx-0'
            >
             Yenilikçi çözümler ve etkili projeler geliştirmek için iş birliğine ve yeni fırsatlara her zaman açığım. 
             Eğer sizinle birlikte çalışabileceğimiz bir proje veya iş imkanı olduğunu düşünüyorsanız, benimle iletişime geçmekten çekinmeyin.
            </motion.p>
            <motion.div
              variants={fadeIn('up', 0.6)}
              initial='hidden'
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className='w-full flex justify-center items-center mb-12 mx-auto lg:mx-0 gap-x-6'
            >
              <button className='btn btn-lg' onClick={handleContactClick}>Bana ulaş</button>
              {/* <a href='#' className='text-gradient btn-link'>
                My Portfolio
              </a> */}
              <motion.div
              variants={fadeIn('up', 0.7)}
              initial='hidden'
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className='flex text-[20px] gap-x-6 max-w-max mx-auto lg:mx-0'
            >
              <a href="https://www.linkedin.com/in/nil1lik/" target="_blank" rel="noopener noreferrer">
  <FaLinkedin />
</a>
              <a href='https://medium.com/@nil1lik' target="_blank" rel="noopener noreferrer">
                <FaMedium />
              </a>
              <a href='https://github.com/nil1lik' target="_blank" rel="noopener noreferrer">
                <FaGithub />
              </a>
            </motion.div>
            </motion.div>            
          </div>
          {/* image */}
          {/* <motion.div
            variants={fadeIn('down', 0.5)}
            initial='hidden'
            whileInView={'show'}
            className='hidden lg:flex flex-1 max-w-[320px] lg:max-w-[482px]'
          >
            <img src={Image} alt='' />
          </motion.div> */}
        </div>
      </div>
    </section>
  );
};

export default Banner;
