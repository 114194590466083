import React, { useRef } from 'react';
// motion
import { motion } from 'framer-motion';
// variants
import { fadeIn } from '../variants';
import emailjs from '@emailjs/browser';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 


const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    // Form verilerini alıyoruz
    const formData = new FormData(form.current);
    const userName = formData.get('user_name');
    const userEmail = formData.get('user_email');
    const subject = formData.get('subject');
    const message = formData.get('message');

    // Form alanlarının boş olup olmadığını kontrol ediyoruz
    if (!userName || !userEmail || !subject || !message) {
      toast.error('Lütfen tüm alanları doldurun.');
      return;
    }

    const emailData = {
      user_name: formData.get('user_name'), // Formdan gelen isim
      user_email: formData.get('user_email'), // Formdan gelen email
      subject: formData.get('subject'), // Formdan gelen email
      message: formData.get('message'), // Formdan gelen mesaj
      reply_to: formData.get('user_email') // Yanıtlanacak email adresi
    };

    // EmailJS ile veriyi gönderiyoruz
    emailjs.send(
      'service_2qn4kxc', // Service ID
      'template_b24lvj8', // Template ID
      emailData,
      'ybZBfjBzjbHMmL9zT' // User ID
    ).then(
      (result) => {
        toast.success('Mesaj başarıyla gönderildi!');
      },
      (error) => {
        toast.error('Mesaj gönderimi sırasında hata oluştu.');
      }
    );

    e.target.reset(); // Formu sıfırlar
  };

  return (
    <section className='py-16 lg:section' id='contact'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row'>
          {/* text */}
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 flex justify-start items-center'
          >
            <div>
              <h4 className='text-xl uppercase text-accent font-medium mb-2 tracking-wide'>
                İletİşİme geç!
              </h4>
              <h2 className='text-[45px] lg:text-[90px] leading-none mb-12'>
                Hadi beraber <br /> çalışalım!
              </h2>
            </div>
          </motion.div>
          {/* form */}
          <motion.form
            ref={form} // Form referansını ekleyin
            onSubmit={sendEmail} // Formun onSubmit olayını yakalayın
            variants={fadeIn('left', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 border rounded-2xl flex flex-col gap-y-6 pb-24 p-6 items-start mb-20'
          >
            <input
              className='bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all'
              type='text'
              name='user_name'
              placeholder='Ad-Soyad'
            />
            <input
              className='bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all'
              name='user_email'
              type='email' 
              placeholder='E-mail'
            />
            <input
              className='bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all'
              type='text'
              name='subject'
              placeholder='Konu'
            />
            <textarea
              className='bg-transparent border-b py-12 outline-none w-full placeholder:text-white focus:border-accent transition-all resize-none mb-12'
              name='message'
              placeholder='Benimle hangi konuda iletişime geçmek istiyorsunuz?'
            ></textarea>
            <button type='submit' className='btn btn-lg'>
              Gönder
            </button>
          </motion.form>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default Contact;
