import React from 'react';
// intersection observer hook
import { useInView } from 'react-intersection-observer';
// motion
import { motion } from 'framer-motion';
// variant
import { fadeIn } from '../variants';

const About = () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
  }); 
  const handleContactClick = () => {
    window.location.href = "mailto:birliknil@gmail.com?subject=Let's%20Work%20Together&body=Hi,%20I%20would%20like%20to%20discuss%20a%20project%20with%20you.";
  };
  return (
    <section className='section mb-40' id='about' ref={ref}>
      <div className='container mx-auto'>
        <div className='flex flex-col gap-y-10 lg:flex-row lg:items-center lg:gap-x-20 lg:gap-y-0 h-screen'>
          {/* img */}
          <motion.div
  variants={fadeIn('right', 0.3)}
  initial='hidden'
  whileInView={'show'}
  viewport={{ once: false, amount: 0.3 }}
  className='flex-1 bg-about bg-cover bg-no-repeat h-[640px] mix-blend-lighten bg-top hidden sm:block'
  style={{ transition: 'none' }}
></motion.div>

          {/* text */}
          <motion.div
            variants={fadeIn('left', 0.5)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 relative z-10'
            style={{ transition: 'none' }}
          >
            <h2 className='h2 text-accent'>NEDEN BENİMLE ÇALIŞMALISINIZ?</h2>
            <h3 className='h3 mb-4'>
              "Dünyayı değiştirebileceğini düşünecek kadar çılgın olanlar, dünyayı değiştirenlerdir."
            </h3>
            <p className='mb-6'>
            Sanırım ben de o çılgınlardan biriyim. Hayatta imkansız diye bir şeyin olmadığını düşünüyorum ve hedeflerime ulaşmak için elimden geleni yapıyorum. Yazılım dünyasına olan yolculuğum bir tutkuyla başladı. Küçük yaştan itibaren teknolojiye meraklıyım, bu ilgi beni sürekli üretmeye ve sorunlara farklı çözümler aramaya yönlendirdi.

            Bu süreçte, yazılımın sadece düşüncelerimi hayata geçirebileceğim değil, aynı zamanda dünyayı değiştirebileceğim bir alan olduğunu fark ettim. Şimdi, bu yolda hem öğreniyor hem de gelişiyorum.

            </p>
            
            <div className='flex gap-x-8 items-center'>
              <button className='btn btn-lg' onClick={handleContactClick}>Bana Ulaş</button>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;
